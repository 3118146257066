export function formatDate(value) {
  // 计算日期相关值
  let time = typeof value == "number" ? new Date(value) : value;
  let Y = time.getFullYear();
  let M = time.getMonth() + 1;
  let D = time.getDate();
  // 如果传递了type的话
  return Y + "-" + (M < 10 ? "0" + M : M) + "-" + (D < 10 ? "0" + D : D);
}
// 日期加一天
export function formatDateAdd(value) {
  // 计算日期相关值
  let time = typeof value == "number" ? new Date(value) : value;
  let Y = time.getFullYear();
  let M = time.getMonth() + 1;
  let D = time.getDate() + 1;
  // 如果传递了type的话
  return Y + "-" + (M < 10 ? "0" + M : M) + "-" + (D < 10 ? "0" + D : D);
}

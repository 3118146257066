<template>
  <div class="container eventReport-warp flex-column">
    <div class="common-head">
      <img
        :src="require('@/assets/image/personbg.png')"
        alt=""
        class="bg-img"
      />
      <img class="img-title" :src="require('@/assets/image/sjsb.png')" alt="" />
      <div class="head-bottom-warp">
        <div
          class="align-center head-item"
          v-for="(item, index) in list"
          :key="index"
          :class="activIndex == index ? 'blue' : 'black'"
          @click="handleClick(index)"
        >
          <div class="bg-blur"></div>
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
    <div v-if="activIndex === 0" class="panel-warp flexone">
      <ul class="panel-warp-ul">
        <li>
          <div class="panel-item-left bra3 panel-item-block">
            事件名称<span class="red">*</span>
          </div>
          <div class="panel-item-right bra3 panel-item-block plr">
            <input
              v-model="ruleforms.name"
              class="panel-input"
              placeholder="请输入事件名称"
            />
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">事件类型</div>
          <div
            class="panel-item-right bra3 panel-item-block plr"
            @click="eventTypeShow = true"
          >
            {{
              ruleforms.type
                ? $dt.changeCodeName("event_type", ruleforms.type)
                : "请选择"
            }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">事件等级</div>
          <div class="panel-item-right bra3 panel-item-block plr">
            <van-radio-group
              v-model="ruleforms.level"
              checked-color="rgb(255, 77, 79)"
              direction="horizontal"
              class="red"
              icon-size="16px"
            >
              <van-radio
                :name="item.value"
                v-for="(item, index) in event_level"
                :key="index"
                >{{ item.text }}</van-radio
              >
            </van-radio-group>
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            事件地点
          </div>
          <div
            class="panel-item-right bra3 panel-item-block plr"
            @click="handleAddArea"
          >
            {{ fieldValue || "请选择" }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            事件说明
          </div>
          <div class="panel-item-right bra3 panel-item-block plr">
            <textarea
              v-model="ruleforms.info"
              placeholder="请输入"
              rows="2"
            ></textarea>
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            <div>
              <div class="t-c">拍照上传</div>
              <div class="f-s10 t-c m-t4">最多支持6张</div>
            </div>
          </div>
          <div class="panel-item-right bra3 panel-item-block plr">
            <van-uploader
              v-model="fileImgList"
              :before-read="handleImagebefread"
              :after-read="modelImgRead"
              multiple
              max-count="6"
            />
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            视频上传
          </div>
          <div class="panel-item-right bra3 panel-item-block plr">
            <van-uploader
              v-model="fileVideoList"
              multiple
              max-count="6"
              class="video-upload"
              :before-read="handlebefread"
              :after-read="modelVideoRead"
              @click-preview="handlePreview"
              accept="video/*"
            >
              <div slot="preview-cover">
                <video
                  v-for="(item, index) in fileVideoList"
                  :key="index"
                  style="object-fit: cover"
                  :src="item.content"
                  :poster="
                    item.content + '?x-oss-process=video/snapshot,t_1,m_fast'
                  "
                ></video>
              </div>
            </van-uploader>
          </div>
        </li>
      </ul>
      <div class="warp-btn">
        <van-button type="info" color="rgb(38, 120, 255)" @click="handleSubmit"
          >立即提交</van-button
        >
      </div>
    </div>
    <template v-else>
      <div class="date-bottom-warp">
        <div class="date-item align-left blue">
          <div class="bg-blur"></div>
          <div class="date-name" @click="show = true">
            <span class="p-l3">{{ date }}</span>
            <span
              ><img :src="require('@/assets/image/path7.png')" class="img-icon"
            /></span>
          </div>
          <div class="date-button">
            <span class="van-button m-r8" @click="handleSearch">查 询</span>
            <span class="van-button" @click="handleReset">重 置</span>
          </div>
        </div>
      </div>
      <div class="flexone">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
          v-if="dataList.length && dataList.length > 0"
          offset="50"
        >
          <div
            class="panelInfo-content"
            v-for="(item, index) in dataList"
            :key="index"
            @click="routerTo(item)"
          >
            <div class="panelInfo-top">
              <div class="panelInfoTop-left">
                <img
                  :src="require('@/assets/image/path5.png')"
                  alt=""
                  class="path5-img"
                />
                <span class="panelInfoTop-name">{{ item.name }}</span>
              </div>
              <div class="panelInfoTop-right">
                <span
                  class="yuandian yuandian-blue"
                  v-if="item.level == '1'"
                ></span>
                <span class="yuandian yuandian-red" v-else></span>
              </div>
            </div>
            <div class="panelInfo-bottom">
              <div class="panelInfoBottom-date">{{ item.time || "无" }}</div>
              <div class="panelInfoBottom-right">
                <img :src="require('@/assets/image/path6.png')" alt="" />
                <span class="f-s10 m-l8">{{
                  $dt.changeCodeName("event_type", item.type)
                }}</span>
              </div>
            </div>
          </div></van-list
        >
        <div class="nothing" v-if="!dataList.length">
          <van-empty description="暂无数据" />
        </div>
      </div>
    </template>
    <van-calendar
      v-model="show"
      type="range"
      color="#3a86ff"
      @confirm="onConfirm"
      :min-date="minDate"
    />
    <van-popup v-model="showArea" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        :field-names="fieldNames"
        title="请选择所在地区"
        :options="areaTree"
        @close="showArea = false"
        @finish="onFinish"
        active-color="#3a86ff"
      />
    </van-popup>
    <van-dialog
      style="width: 100%; border-radius: 0; height: 200px"
      theme="default"
      v-model="showvideoplay"
      :show-cancel-button="false"
      :show-confirm-button="false"
      closeOnClickOverlay
    >
      <!-- this.videoCover = this.videoUrl + '?x-oss-process=video/snapshot,t_1,m_fast' -->
      <video
        controls
        preload="auto"
        style="width: 100%; height: 200px; object-fit: contain"
        :src="videourl"
        webkit-playsinline
        playsinline
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        x5-video-orientation="portraint"
        x-webkit-airplay="true"
        v-if="videourl"
      ></video>
    </van-dialog>
    <!-- 事件类型 -->
    <van-popup v-model="eventTypeShow" position="bottom">
      <van-picker
        title="选择事件类型"
        show-toolbar
        :columns="event_type"
        @confirm="onConfirmEvent"
        @cancel="onCancelEvent"
        value-key="text"
      />
    </van-popup>
  </div>
</template>

<script>
import mixin from "@/mixin/eventMixin";
import { mapState } from "vuex";
import httpService from "@/plugins/http";
import { Toast } from "vant";
import * as imageConversion from "image-conversion";
export default {
  mixins: [mixin],
  data() {
    return {
      minDate: new Date(1990, 0, 1),
      interfaceUrl: "/api/event/get",
      otherParams: {
        create_phone: "",
      },
      list: ["事件上报", "已上报事件"],
      fileImgList: [],
      fileVideoList: [],
      ruleforms: {
        name: "",
        level: "",
      },
      showArea: false,
      cascaderValue: "",
      fieldValue: "",
      videourl: "",
      fieldNames: {
        text: "name",
        value: "id",
        children: "node",
      },
      showvideoplay: false,
      eventTypeShow: false,
      address: "", // 当前地址
      curlatitude: "", // 当前地址经度
      curlongitude: "", // 当前地址维度
    };
  },
  created() {
    this.otherParams.create_phone = this.userInfo.loginAcc;
  },
  computed: {
    ...mapState(["areaTree", "event_level", "userInfo", "event_type"]),
  },
  mounted() {
    this.getlocation();
    // console.log(
    //   this.getVideoBase64(
    //     "https://liujiajicengzhili.top/upload/10365925674415032.mp4"
    //   )
    // );
  },
  methods: {
    // getCurAddress({ lng, lat }) {
    //   // const queryLng = this.$route.query.lng;
    //   // const queryLat = this.$route.query.lat;
    //   // if (queryLng && queryLat) {
    //   //   var point = new BMap.Point(queryLng, queryLat);
    //   // } else {
    //     var point = new BMap.Point(lng, lat);
    //   // }
    //   var gc = new BMap.Geocoder();
    //   let _this = this;
    //   gc.getLocation(point, function (rs) {
    //     _this.address = rs.address;
    //   });
    // },
    async getVideoBase64(url, second = 0) {
      const video = document.createElement("video");
      video.setAttribute("crossOrigin", "anonymous"); // 处理跨域
      video.setAttribute("src", url);
      // 静音操作，防止播放失败
      video.setAttribute("muted", "muted");
      video.addEventListener("loadeddata", async () => {
        const canvas = document.createElement("canvas");
        const { width, height } = video; // canvas的尺寸和图片一样
        canvas.width = width;
        canvas.height = height;

        if (second) {
          video.currentTime = second;
          // 播放到当前时间的帧，才能截取到当前的画面
          await video.play();
          await video.pause();
        }

        canvas.getContext("2d")?.drawImage(video, 0, 0, width, height);
        return canvas.toDataURL("image/jpeg");
      });
    },
    getlocation() {
      try {
        const geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition((r) => {
          this.curlatitude = r.latitude;
          this.curlongitude = r.longitude;
          console.log(this.curlatitude, this.curlongitude);
          // this.getCurAddress({ lng: this.curlongitude, lat: this.curlatitude });
        });
      } catch (e) {
        console.log(e);
      }
    },
    onConfirmEvent(item) {
      this.ruleforms.type = item.value;
      this.eventTypeShow = false;
    },
    onCancelEvent() {
      this.eventTypeShow = false;
    },
    routerTo(row) {
      this.$router.push({
        path: "eventReportingDetail",
        query: {
          id: row.id,
        },
      });
    },
    handleAddArea() {
      this.showArea = true;
    },
    onFinish({ selectedOptions, value }) {
      this.showArea = false;
      this.fieldValue = selectedOptions.map((option) => option.name).join("/");
      this.ruleforms.area_id = value;
    },
    async modelImgRead(file) {
      if (file instanceof Array) {
        file.map((v) => {
          // v.status = "uploading";
          // v.message = "上传中...";
          this.fileUpload(v);
        });
      } else {
        // file.status = "uploading";
        // file.message = "上传中...";
        this.fileUpload(file);
      }
    },
    async uploadImg(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      const res = await httpService.post("/api/sys/upload_file", formData);
      if (res.code === 0) {
        if (file instanceof Array) {
          //判断是否是数组
          file.map((v, i) => {
            v.status = "success";
            v.message = "";
            v.path = res[i];
          });
        } else {
          file.status = "success";
          file.message = "";
          file.path = res.path;
        }
      }
    },
    handleImagebefread(file) {
      return new Promise((resolve, reject) => {
        console.log("压缩前", file);
        // Toast(file.size);
        // console.log('压缩前', file) // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
        // 500是控制压缩大小的 根据你自己的需要调整 数值越小压缩越小
        imageConversion.compressAccurately(file, 1024).then((res) => {
          res = new File([res], file.name, {
            type: res.type,
            lastModified: Date.now(),
          });
          console.log("压缩后", res);
          resolve(res);
        });
      });
    },
    handlebefread(file) {
      if (file.size > 104857600) {
        Toast("选择/录制视频不能超过100M");
        return false;
      }
      return true;
    },
    handlePreview(file) {
      this.videourl = file.content;
      this.showvideoplay = true;
    },
    async modelVideoRead(file) {
      if (file instanceof Array) {
        file.map((v) => {
          // v.status = "uploading";
          // v.message = "上传中...";
          this.fileUpload(v);
        });
      } else {
        // file.status = "uploading";
        // file.message = "上传中...";
        this.fileUpload(file);
      }
    },
    fileUpload(file) {
      let _file = file;

      _file.status = "uploading";
      _file.message = "上传中...";

      //创建表单
      var avatarData = new FormData();
      avatarData.append("file", file.file); //将图片加入上传表单

      var xhr = new XMLHttpRequest();
      xhr.open("post", "/api/sys/upload_file", true);
      xhr.upload.onprogress = progressFunction; //【上传进度调用方法实现】
      xhr.onload = uploadComplete; //请求完成
      xhr.onerror = uploadFailed; //请求失败
      xhr.onreadystatechange = function () {
        //当readyState变化时执行 （作为上传结果不可靠）
      };
      //上传进度
      function progressFunction(evt) {
        // event.total是需要传输的总字节，event.loaded是已经传输的字节。如果event.lengthComputable不为真，则event.total等于0
        if (evt.lengthComputable) {
          //vant框架这个没多大用处
          let complete = ((evt.loaded / evt.total) * 100) | 0;
          _file.upload_progress = complete;
        }
      }
      //上传成功响应
      function uploadComplete(evt) {
        //服务断接收完文件返回的结果
        if (evt.target.readyState == 4 && evt.target.status == 200) {
          var res = eval("(" + evt.target.responseText + ")");
          // file.url=res.result.url + "/" + res.result.imgs.file;
          _file.path = res.path; //用于表单提交保存到数据库
          _file.status = "done";
          _file.message = "上传成功";
        } else {
          _file.status = "failed";
          _file.message = "上传失败";
        }
        // Toast("上传成功！");
      }
      //上传失败
      function uploadFailed(evt) {
        _file.status = "failed";
        _file.message = "上传失败";
      }
      //开始上传
      xhr.send(avatarData);
    },
    async uploadVideo(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      const res = await httpService.post("/api/sys/upload_file", formData);
      if (res.code === 0) {
        if (file instanceof Array) {
          //判断是否是数组
          file.map((v, i) => {
            v.status = "success";
            v.message = "";
            v.path = res[i];
          });
        } else {
          file.status = "success";
          file.message = "";
          file.path = res.path;
        }
      }
    },
    // 提交
    async handleSubmit() {
      if (!this.ruleforms.name) {
        Toast("请输入事件名称");
        return;
      }
      try {
        this.ruleforms.pic = this.fileImgList
          .map((item) => {
            return item.path;
          })
          .join(",");
        this.ruleforms.video = this.fileVideoList
          .map((item) => {
            return item.path;
          })
          .join(",");
        this.$store.commit("showLoading");

        const { code } = await httpService.post("/api/event/add", {
          ...this.ruleforms,
          create_by: this.userInfo.loginAcc,
          pos: this.curlongitude + "," + this.curlatitude,
        });
        if (code === 0) {
          Toast("提交成功");
          this.fileImgList = [];
          this.fileVideoList = [];
          this.ruleforms = {};
          this.cascaderValue = "";
          this.fieldValue = "";
          this.showvideoplay = false;
          this.videourl = "";
        } else {
          this.$store.commit("hideLoading");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("hideLoading");
      }
    },
  },
};
</script>

<style lang="less">
.eventReport-warp {
  background: rgb(248, 248, 248);
  letter-spacing: 0px;
  position: relative;
  .path5-img {
    width: 14px;
    height: 16px;
    object-fit: cover;
  }
  .warp-btn {
    display: flex;
    padding: 20px;
    justify-content: center;
    .van-button {
      width: 112px;
    }
  }
  .video-upload {
  }
}
</style>

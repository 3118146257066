import httpService from "@/plugins/http";
// import { formatDate, formatDateAdd } from "@/utils/formdate";
import { formatDate } from "@/utils/formdate";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      forms: {
        begin: "",
        end:""
        // begin: formatDate(new Date()),
        // end: formatDateAdd(new Date()),
      },
      activIndex: 0,
      dataList: [],
      // date: formatDate(new Date()) + "~" + formatDateAdd(new Date()),
      date: "",
      show: false,
      currentPage: 1,
      pageSize: 10,
      total: 0, // 总条数
    };
  },
  methods: {
    handleClick(index) {
      this.activIndex = index;
      if (this.activIndex == 1) {
        this.currentPage = 1;
        this.getInit()
      }
    },
    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      this.date = `${formatDate(start)} ~ ${formatDate(end)}`;
      this.forms.begin = formatDate(start);
      this.forms.end = formatDate(end);
    },
    async getInit() {
      try {
        if (this.currentPage === 1) {
          this.$store.commit("showLoading");
        }
        let req = { ...this.forms };
        req.begin = this.forms.begin ? ( this.forms.begin + " " + "00:00:00" ): '';
        req.end = this.forms.end ?( this.forms.end + " " + "23:59:59" ): '';
        const res = await httpService.post(this.interfaceUrl, {
          ...req,
          area_id: [],
          start_page: this.currentPage, //开始页0开始
          size_per_page: this.pageSize,
          ...(this.otherParams || {})
        });
        if (res.code === 0) {
          if (this.currentPage === 1) {
            this.dataList = res.data || [];
          } else {
            let records = res.data || [];
            this.dataList = this.dataList.concat(records);
          }
          this.total = Math.ceil(res.total / this.pageSize);
          this.currentPage = this.currentPage + 1;
          this.loading = false;
        } else {
          this.$store.commit("hideLoading");
        }
      } catch (error) {
        console.log(error, "error");
      } finally {
        this.$store.commit("hideLoading");
      }
    },
    onLoad() {
      // 异步更新数据
      if (this.currentPage === 1) {
        this.getInit();
      } else {
        if (this.currentPage <= this.total) {
          this.getInit();
        } else {
          this.finished = true;
        }
      }
    },
    handleSearch() {
      this.currentReset();
      this.getInit();
    },
    handleReset() {
      this.forms = this.$options.data.call(this).forms;
      this.date = `${this.forms.begin} ~ ${this.forms.end}`;
      this.handleSearch();
    },
    currentReset() {
      this.currentPage = 1;
      this.total = 0;
      this.loading = false;
      this.finished = false;
      this.dataList = [];
    },
  },
  mounted() {
    if (this.$route.query.type == 1) {
      this.activIndex = 1
    }
    this.getInit();
  
  },
};
